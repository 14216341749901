import fetch from 'isomorphic-fetch';
import { API_URL } from '../config/config';

function getOriginHeader() {
  if (process.env.GATSBY_BRAND) {
    return `platform-${process.env.GATSBY_BRAND}`;
  }
  return 'site';
}

export default function callApi(endpoint, method = 'get', body) {
  const jwtToken = typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('token') : undefined;
  return fetch(`${API_URL}/${endpoint}`, {
    headers: Object.assign({
      'Content-Type': 'application/json',
      'Origin-tilli': getOriginHeader(),
    }, jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
    method,
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status === 204) {
        return { json: {}, response };
      }
      return response.json()
        .then(json => ({ json, response }))
        .catch(() => ({ json: {}, response }));
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      if (json.token && typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem('token', json.token);
      }

      return json;
    });
}
