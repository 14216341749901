import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import routesMap from '../../Routes';
import { trackEvent } from '../../services/analytics';
import DropdownList from './v2/DropdownList';
import OutInSideClick from './v2/OutInSideClick';
import {
  contentWidth, margins, colors, fontSizes, mobileThresholdPixels,
  menuBarHeightPixels, mobileMenuBarHeightPixels, Row,
} from '../../components/Home/v2/StyledComponents';

import dropdownArrow from '../../assets/arrow-dropdown-list.svg';
import logoTilli from '../../assets/logos/tilli.navy.svg';

const servicesList = [
  { index: 0, text: 'Réserver', url: routesMap.OrderPage.url, trackingId: 'reserver' },
  { index: 1, text: 'Comment ça marche', url: routesMap.HowItWorksPage.url, trackingId: 'comment-ca-marche' },
  { index: 2, text: 'Notre histoire', url: routesMap.AboutPage.url, trackingId: 'notre-histoire' },
  { index: 3, text: 'Ils ont aimé', url: routesMap.FeedbacksPage.url, trackingId: 'ils-ont-aimé' },
  { index: 4, text: 'Ils parlent de nous', url: routesMap.PressPage.url, trackingId: 'ils-parlent-de-nous' },
  { index: 5, text: 'Nos artisans', url: routesMap.OurArtisans.url, trackingId: 'nos-couturiers' },
  { index: 6, text: 'Nos tarifs', url: routesMap.PricingsPage.url, trackingId: 'nos-tarifs' },
  { index: 7, text: 'FAQ', url: routesMap.FAQPage.url, trackingId: 'faq' },
];

const partnersList = [
  { index: 0, text: 'Nos partenaires', url: routesMap.PartnersPage.url, trackingId: 'nos-partenaires' },
  { index: 1, text: 'Devenir partenaire', url: routesMap.WhyBecomePartner.url, trackingId: 'devenir-partenaire' },
];

const mobileMenu = [
  { index: 0, text: 'Réserver', url: routesMap.OrderPage.url, trackingId: 'Reserver' },
  { index: 1, text: 'Comment ça marche ?', url: routesMap.HowItWorksPage.url, trackingId: 'Comment-ca-marche ?' },
  {
    index: 2,
    text: 'FAQ',
    url: routesMap.FAQPage.url,
    trackingId: 'FAQ',
  },
  { index: 3, text: 'Notre histoire', url: routesMap.AboutPage.url, trackingId: 'Notre-histoire' },
  { index: 4, text: 'Ils ont aimé', url: routesMap.FeedbacksPage.url, trackingId: 'Ils-ont-aime' },
  {
    index: 5,
    text: 'Ils parlent de nous / Presse',
    url: routesMap.PressPage.url,
    trackingId: 'Ils-parlent-de-nous-Presse',
  },
  { index: 6, text: 'Devenir partenaire', url: routesMap.WhyBecomePartner.url, trackingId: 'Devenir-partenaire' },
  { index: 7, text: 'Nos partenaires', url: routesMap.PartnersPage.url, trackingId: 'Nos-partenaires' },
  { index: 8, text: 'Nos tarifs', url: routesMap.PricingsPage.url, trackingId: 'nos-tarifs' },
  { index: 9, text: 'Devenir couturier', url: routesMap.BeTillistePage.url, trackingId: 'Devenir-couturier' },
  {
    index: 10,
    text: 'Rejoindre l\'aventure Tilli',
    url: routesMap.JobOfferPage.url,
    trackingId: 'Rejoindre-aventure-Tilli',
  },
  { index: 11, text: 'Nos artisans', url: routesMap.OurArtisans.url, trackingId: 'Nos-artisans' },
  { index: 12, text: 'La rubrique / Blog', url: routesMap.LaRubrique.url, trackingId: 'La-rubrique-Blog' },
];

const BarContainer = styled.div`
  position: fixed;
  top: 0px;
  width: 100vw;
  height: ${menuBarHeightPixels};
  z-index: 3;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, .3);

  @media (max-width: ${mobileThresholdPixels}) {
    background-color: ${colors.navy};
    height: ${mobileMenuBarHeightPixels};
    border-bottom-width: 0px;
  }
`;

const Content = styled.div`
  position: relative;
  width: ${contentWidth}px;
  margin: auto;
  display: flex;
  align-items: center;
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const TextLink = styled.div`
  width: max-content;
  color: ${colors.navy};
  font-family: Gotham;
  font-size: ${fontSizes.s};
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0px ${margins.m} 0px 0px;
  ${props => props.right && `padding: 0px 0px 0px ${margins.m};`};
  ${props => props.middle && 'padding: 0px'};
  flex: 1;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    padding: ${props => props.right ? `0px ${margins.s} 0px 0px ` : `0px 0px 0px ${margins.s}`};
    color: ${colors.white};
    font-size: ${fontSizes.s};
    text-align: center;
  }
`;

const LinkContainer = styled.div`
  text-align: center;
  ${props => props.logo && 'margin: auto'};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
  }
`;

const LeftPart = styled.div`
  position: absolute;
  left: 0px;
  display: flex;
  flex-direction: row;
`;

const RightPart = styled.div`
  position: absolute;
  right: 15px;
  display: flex;
  flex-direction: row;
`;


const LogoContainer = styled(Row)`
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    height: 100px;
    border-bottom: solid 2px ${colors.lightGrey};
  }
`;

const LogoImg = styled.img`
  width: 200px;
`;

const Img = styled.img`
  padding-bottom: 1px;
  margin-left: 4px;
  ${props => props.rotate && 'transform: rotate(180deg)'};
`;

const StyledLink = styled(Link)`
  flex: 1;
  text-align: center;
  text-decoration: none;
`;

class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayServicesList: false,
      displayPartnersList: false,
      displayMobileMenuList: false,
    };
  }

  renderDesktop() {
    return (
      <Content>
        <LeftPart>
          <LinkContainer>
            <OutInSideClick handleClickOutside={() => this.setState({ displayServicesList: false })} >
              <div>
                <DropdownList display={!!this.state.displayServicesList} dropdownlist={servicesList} />
                <TextLink
                  onMouseDown={() => this.setState({ displayServicesList: !this.state.displayServicesList })}
                >
                  {'Le service '}
                  <Img
                    src={dropdownArrow}
                    rotate={this.state.displayServicesList ? 'true' : undefined}
                    alt="Menu déroulant"
                  />
                </TextLink>
              </div>
            </OutInSideClick>
          </LinkContainer>
          <LinkContainer>
            <OutInSideClick handleClickOutside={() => this.setState({ displayPartnersList: false })} >
              <div>
                <DropdownList display={!!this.state.displayPartnersList} dropdownlist={partnersList} />
                <TextLink
                  onMouseDown={() => this.setState({ displayPartnersList: !this.state.displayPartnersList })}
                >
                  {'Partenaire  '}
                  <Img
                    src={dropdownArrow}
                    rotate={this.state.displayPartnersList ? 'true' : undefined}
                    alt="Menu déroulant"
                  />
                </TextLink>
              </div>
            </OutInSideClick>
          </LinkContainer>
        </LeftPart>
        <LinkContainer logo>
          <StyledLink
            to={routesMap.HomePage.url}
            onClick={() => trackEvent('click', 'home_menu')}
          >
            <TextLink middle>
              <LogoImg src={logoTilli} alt={'Logo du service de couturière Tilli '} />
            </TextLink>
          </StyledLink>
        </LinkContainer>
        <RightPart>
          <LinkContainer>
            <StyledLink
              to={routesMap.LaRubrique.url}
              onClick={() => trackEvent('click', 'home_menu-mon_compte')}
            >
              <TextLink>{'Inspirations'}</TextLink>
            </StyledLink>
          </LinkContainer>
          <LinkContainer>
            <StyledLink
              to={routesMap.PricingsPage.url}
              onClick={() => trackEvent('click', 'home_menu-nos-tarifs')}
            >
              <TextLink right>{'Nos tarifs'}</TextLink>
            </StyledLink>
          </LinkContainer>
        </RightPart>
      </Content>
    );
  }

  renderMobile() {
    return (
      <StyledRow justifyContent={'space-between'}>
        <LinkContainer>
          <OutInSideClick handleClickOutside={() => this.setState({ displayMobileMenuList: false })} >
            <DropdownList display={!!this.state.displayMobileMenuList} dropdownlist={mobileMenu} />
            <TextLink
              onMouseDown={() => this.setState({ displayMobileMenuList: !this.state.displayMobileMenuList })}
            >
              {'Menu'}
            </TextLink>
          </OutInSideClick>
        </LinkContainer>
        <LinkContainer>
          <StyledLink
            to={routesMap.LaRubrique.url}
            onClick={() => trackEvent('click', 'home_menu-mon_compte')}
          >
            <TextLink right>{'Inspirations'}</TextLink>
          </StyledLink>
        </LinkContainer>
      </StyledRow>
    );
  }

  render() {
    const { isMobile } = this.props.context;
    return (
      <div>
        <BarContainer>
          {!isMobile ? this.renderDesktop() : this.renderMobile()}
        </BarContainer>
        {isMobile &&
          <LogoContainer justifyContent={'center'} alignItems={'center'}>
            <StyledLink
              to={routesMap.HomePage.url}
              onClick={() => trackEvent('click', 'home_menu-logoTilli')}
            >
              <LogoImg src={logoTilli} alt={'logo Tilli atelier de couturières'} />
            </StyledLink>
          </LogoContainer>
        }
      </div>
    );
  }
}

MenuBar.propTypes = {
  landingVersion: PropTypes.bool,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

MenuBar.defaultProps = {
  landingVersion: false,
};

export default withAppContext(MenuBar);
