import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import withAppContext from '../../withAppContext';
import dropdownArrow from '../../assets/arrow-dropdown-list.white.svg';
import routesMap from '../../Routes';
import { trackEvent } from '../../services/analytics';
import { colors, fontSizes, fontWeight, margins, mobileThresholdPixels, Icon, Title2Footer, ContainerFooter, Content }
  from '../../components/Home/v2/StyledComponents';
import facebook from '../../assets/footer/facebook.svg';
import instagram from '../../assets/footer/instagram.svg';

const footerContent = [
  {
    index: 0,
    title: 'Le service Tilli',
    contactInfos: true,
    links: [
      { text: 'Réserver', url: routesMap.OrderPage.url, trackingId: 'Reserver' },
      { text: 'Comment ça marche ?', url: routesMap.HowItWorksPage.url, trackingId: 'Comment-ca-marche ?' },
      { text: 'Mon compte', url: routesMap.Login.url, trackingId: 'Mon-compte' },
      {
        text: 'FAQ',
        url: routesMap.FAQPage.url,
        trackingId: 'FAQ',
      },
      { text: 'Notre histoire', url: routesMap.AboutPage.url, trackingId: 'Notre-histoire' },
      { text: 'Ils ont aimé', url: routesMap.FeedbacksPage.url, trackingId: 'Ils-ont-aime' },
      { text: 'Ils parlent de nous / Presse', url: routesMap.PressPage.url, trackingId: 'Ils-parlent-de-nous-Presse' },
      { text: 'Nos tarifs', url: routesMap.PricingsPage.url, trackingId: 'nos-tarifs' },
    ],
  },
  {
    index: 1,
    title: 'L‘écosystème Tilli',
    links: [
      { text: 'Devenir partenaire', url: routesMap.WhyBecomePartner.url, trackingId: 'Devenir-partenaire' },
      { text: 'Nos partenaires', url: routesMap.PartnersPage.url, trackingId: 'Nos-partenaires', emptyLineNext: true },
      { text: 'Devenir couturier', url: routesMap.BeTillistePage.url, trackingId: 'Devenir-couturier' },
      {
        text: (<span>{'Rejoindre l\'aventure Tilli'} <br /><br /></span>),
        url: routesMap.JobOfferPage.url,
        trackingId: 'Rejoindre-aventure-Tilli',
      },
    ],
  },
  {
    index: 2,
    title: 'L‘univers Tilli',
    links: [
      { text: 'Notre histoire', url: routesMap.AboutPage.url, trackingId: 'Notre-histoire' },
      { text: 'Nos artisans', url: routesMap.OurArtisans.url, trackingId: 'Nos-artisans' },
      { text: 'La rubrique', url: routesMap.LaRubrique.url, trackingId: 'La-rubrique-Blog', emptyLineNext: true },
      { text: 'Ils ont aimé', url: routesMap.FeedbacksPage.url, trackingId: 'Ils-ont-aime' },
      { text: 'Ils parlent de nous', url: routesMap.PressPage.url, trackingId: 'Ils-parlent-de-nous-Presse' },
    ],
    showRating: true,
  },
];

const StyledContent = styled(Content)`
  padding: ${margins.x2l} 0px;
  background-color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    padding: 0px ${margins.s};
  }
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const TextMainContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  align-content: center;
  margin: ${margins.l} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${margins.m};
  }
`;

const BlockSuperContainer = styled.div`
  text-align: initial;
`;

const BlockContainer = styled.div`
  font-size: ${fontSizes.s};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin : ${props => !props.last ? `0px ${margins.l} 0px 0px` : `0px  0px 0px ${margins.s}`};
  text-align: initial;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 15px;
    margin: 0px;
    align-items: center;
  }
`;

const TextContainer = styled.div`
  color: white;
  font-family: Roboto;
  font-size: ${fontSizes.m};
  font-weight: ${props => props.title ? `${fontWeight.medium}` : `${fontWeight.light}`};
  ${props => props.title && 'text-transform: uppercase'};
  ${props => props.title && 'letter-spacing: 1px'};
  line-height: 24px;
  ${props => props.pointer && 'cursor: pointer'};

  @media (max-width: ${mobileThresholdPixels}) {
    font-weight: ${fontWeight.regular};
    text-align: center;
    ${props => props.link && `padding-top: ${margins.s};`}
  }
`;

const MenusContainer = styled.div`
  border-color: ${colors.white};
  border-style: solid;
  border-width: 1px 0px;
  margin: ${margins.m} auto 0px;
`;

const MenuContainer = styled.div`
  margin: ${margins.m} auto;
`;

const MenuTitle = styled.h3`
  margin: ${margins.s} 0px;
  color: white;
  font-family: Roboto;
  font-weight: 200;
  font-size: ${fontSizes.m};
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 24px;
  text-align: center;
`;

const StyledIcon = styled(Icon)`
  margin: ${margins.l} ${margins.s} 0px ${margins.s};
`;

const Img = styled.img`
  padding-bottom: 1px;
  margin-left: 4px;
  width: 10px;
  ${props => props.rotate && 'transform: rotate(180deg)'};
`;

const renderRating = (
  <Link
    style={{ textDecoration: 'none', color: 'inherit' }}
    to={routesMap.FeedbacksPage.url}
    onClick={() => trackEvent('click', 'Ils-ont-aime_rating-footer')}
  >
    <TextContainer>
      <br /><br />
      <div
        itemScope
        itemType="http://schema.org/LocalBusiness"
      >
        <div
          itemScope
          itemType="http://data-vocabulary.org/Review-aggregate"
        >
          <span itemProp="itemreviewed">Tilli.fr</span> a reçu la note de<br />
          <span
            itemProp="rating"
            itemScope
            itemType="http://data-vocabulary.org/rating"
          >
            <span itemProp="average">9.3</span>{' / '}
            <span itemProp="best">10</span></span> sur <span itemProp="votes">271</span> avis.
        </div>
      </div>
    </TextContainer>
  </Link>
);

class Footer1 extends React.Component {
  constructor() {
    super();
    this.state = { openedIndex: -1 };
  }

  renderDesktop() { // eslint-disable-line class-methods-use-this
    return (
      <React.Fragment>
        {footerContent.map(content =>
          <BlockSuperContainer key={`${content.index}_desktop`}>
            <BlockContainer last={content.index === (footerContent.length - 1)} >
              <TextContainer title="true">{content.title}</TextContainer><br />
              {content.links.map(link =>
                <Link
                  key={link.url}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  to={link.url}
                  onClick={() => trackEvent('click', `${link.trackingId}_block-footer`)}
                >
                  <TextContainer pointer>{link.text}</TextContainer>
                  {link.emptyLineNext && <br />}
                </Link>,
              )}
              {content.contactInfos &&
                <TextContainer>
                  <br /><br />
                  contact@tilli.fr<br />
                  06.23.53.08.13
                </TextContainer>
              }
              {content.showRating && renderRating}
            </BlockContainer>
          </BlockSuperContainer>,
        )}
      </React.Fragment>
    );
  }

  renderMobile() {
    return (
      <div>
        <TextContainer>
          contact@tilli.fr<br />
          06.23.53.08.13
        </TextContainer>
        <MenusContainer>
          {footerContent.map((content, index) =>
            <MenuContainer key={`${content.title}_mobile`}>
              <MenuTitle
                onClick={() => this.setState({ openedIndex: this.state.openedIndex === index ? -1 : index })}
              >
                {content.title}
                <Img
                  alt="Flèche menu déroulant"
                  src={dropdownArrow}
                  rotate={(this.state.openedIndex === index) ? 'true' : undefined}
                />
              </MenuTitle>
              {(this.state.openedIndex === index) && content.links.map(link =>
                <Link
                  key={link.url}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  to={link.url}
                  onClick={() => trackEvent('click', `${link.trackingId}_block-footer`)}
                >
                  <TextContainer link>{link.text}</TextContainer>
                </Link>,
              )}
            </MenuContainer>,
          )}
        </MenusContainer>
        {renderRating}
      </div>
    );
  }

  render() {
    const { isMobile } = this.props.context;
    return (
      <ContainerFooter big>
        <StyledContent background={colors.navy}>
          <SubContainer>
            <div>
              <Title2Footer white>
                {'Simplifiez-vous l\'habit.'}
              </Title2Footer>
              <div>
                <a
                  target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/tilli.couture/"
                  onClick={() => trackEvent('click', 'facebook_reseaux-sociaux')}
                >
                  <StyledIcon src={facebook} alt="page facebook de Tilli un service de retouches à domicile" />
                </a>
                <a
                  target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/tilli.couture/"
                  onClick={() => trackEvent('click', 'instagram_reseaux-sociaux')}
                >
                  <StyledIcon src={instagram} alt="page instagram de Tilli une retoucherie à domicile" />
                </a>
              </div>
            </div>
            <TextMainContainer>
              {!isMobile ? this.renderDesktop() : this.renderMobile()}
            </TextMainContainer>
          </SubContainer>
        </StyledContent>
      </ContainerFooter>
    );
  }
}

Footer1.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(Footer1);
