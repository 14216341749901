import styled from 'styled-components';
import { colors, mobileThresholdPixels, fontSizes, fontWeight, margins }
  from '../styledComponents';
import { menuBarHeightPixels, mobileMenuBarHeightPixels } from '../../components/Home/v2/StyledComponents';
import trait from '../../assets/trait.png';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px 0px;
`;

export const Content = styled.div`
  width: 100%;
  background-color: white;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  background-color: ${colors.white};
  padding-top: ${menuBarHeightPixels};
  font-size: ${fontSizes.m};

  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: ${mobileMenuBarHeightPixels};
  }
`;

const headerPhotoMargin = 100;
export const HeaderPhoto = styled.img`
  margin: 0px ${headerPhotoMargin}px;
  object-fit: cover;
  height: 65vh;
  width: calc(100vw - ${2 * headerPhotoMargin}px);

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
    height: auto;
    width: 100vw;
    object-fit: unset;
  }
`;

export const Button = styled.div`
  display: inline-block;
  min-width: 280px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-size: ${props => props.large ? '24px' : '18px'};
  margin: 20px auto;
  padding: ${props => props.large ? '25px 35px' : '25px'};
  letter-spacing: 1px;
  color: ${colors.sand};
  background-color: ${colors.navy};
  transition: all 0.3s ease;
  ${props => props.white && 'font-size: 13px; min-width: 180px'};

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      transform: scale(1.1);
    }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 20px 25px;
    font-size: ${props => props.verySmall ? '7px' : '14px'};
    margin: 15px auto 18px;
    min-width: ${props => props.verySmall ? '100px' : '220px'};
  }
`;

export const ButtonHeaderContainer = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    position: relative;
    top: -50px;
  }
`;

export const Title1 = styled.h1`
  text-align: center;
  width: 100%;
  color: ${colors.navy};
  font-size: ${props => props.small ? '36px' : '48px'};
  font-weight: 300;
  ${props => props.noMargin && 'margin: 0px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${props => props.small ? '18px' : '22px'};
  }
`;

export const Title2 = styled.h2`
  text-align: center;
  width: 100%;
  color: ${props => props.white ? colors.white : colors.navy};
  font-size: ${props => props.small ? '36px' : '48px'};
  font-weight: 300;
  ${props => props.noMargin && 'margin: 0px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${props => props.small ? '18px' : '22px'};
  }
`;

export const Title3 = styled.h3`
  text-align: ${props => props.left ? 'left' : 'center'};
  width: 100%;
  color: ${colors.navy};
  font-size: 24px;
  font-weight: 300;
  ${props => !props.margin && 'margin: 0px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 20px;
    margin-top: 20px;
  }
`;

export const Banner = styled.div`
  background-image: url(${trait});
  background-repeat: repeat-x;
  height: 60px;
  width: ${props => props.width || '100vw'};
`;

export const Icon = styled.img`
  height: 80px;
  width: 80px;
  margin: 20px;
  ${props => props.framed ? `
    margin: 0px;
    padding: 20px;
    height: 40px;
    width: 40px;
    fill: ${colors.navy};
  ` : ''}

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover{
      transform: scale(1.1) rotate(-7deg);
    }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    height: 60px;
    width: 60px;
    ${props => props.framed ? `
      padding: 16px;
      height: 27px;
      width: 27px;
    ` : ''}
  }
`;

export const A = styled.a`
  padding: ${margins.m} 0px;
  align-self: center;
  color: ${colors.navy};
  font-size: ${fontSizes.l};
  font-weight: 300;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

export const ContainerBis = styled.div`
  margin-top: ${margins.xl};
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${margins.l};
  }
`;

export const SectionHeadBis = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HeadTextSpanBis = styled.span`
  position: relative;
  font-family: Roboto;
  font-size: ${fontSizes.xl};
  font-weight: ${fontWeight.medium};
  text-align: center;
  text-transform: none;
  margin-top: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${margins.s};
    font-size: ${fontSizes.xs};
  }
`;

export const Img = styled.img`
  position: relative;
  width: 100%;
`;
