import styled, { keyframes } from 'styled-components';
import backgroundImage from '../assets/photo-header.jpg';

export const mobileThreshold = 800;
export const mobileThresholdPixels = `${mobileThreshold}px`;
export const smallMobileThresholdPixels = `${mobileThreshold / 2}px`;
export const smallDesktopThresholdPixels = '1300px';

export const margins = {
  xs: '5px',
  s: '11px',
  m: '22px',
  l: '44px',
  xl: '88px',
  x2l: '176px',
  x3l: '352px',
};

export const fontSizes = {
  xs: '8px',
  s: '14px',
  m: '18px',
  l: '22px',
  xl: '26px',
  x2l: '32px',
  x3l: '48px',
  x4l: '60px',
  x5l: '120px',
};

export const fontWeight = {
  light: '200',
  regular: '400',
  medium: '500',
};

export const colors = {
  navy: '#172c42',
  navyTransparent: 'rgba(23,44,66,0.95)',
  grey: '#777',
  lightGrey: '#F7F7F7',
  sand: '#F1E6CA',
  linen: '#C7C0B5',
  error: '#F04F54',
  white: '#FFFFFF',
  whiteTransparent: 'rgba(255,255,255,0.95)',
  warning: '#FFAB4A',
};

export const RelativePosition = styled.div`
  position: relative;
`;

export const AbsolutePosition = styled.div`
  position: absolute;
`;

export const Highlight = styled.span`
  color: ${colors.navy};
  font-weight: bold;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - ${margins.m});
`;

export const RowOnDesktop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(${props => props.backgroundImage || backgroundImage});
  background-color: ${colors.grey};
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top center;
`;

export const Container = styled.section`
  box-sizing: border-box;
  min-height: 100vh;
  height: auto;
  width: 100%;
  overflow: auto;
`;

export const Wrapper = styled.section`
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 98%;
    margin: ${margins.m} 1% 1% 1%;
    padding: 0;
  }
`;

export const P = styled.p`
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
    text-align: justify;
  }
`;

export const Header2 = styled.header`
  text-align: center;
`;

export const Header3 = styled.header``;

export const Title1 = styled.h1`
  font-size: ${fontSizes.xxl};
  margin: ${margins.xl} 0px;
  color: ${colors.navy};
  text-align: center;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} 0px 0px;
    font-size: ${fontSizes.xl};
  }
`;

export const Title1bis = styled.h1`
  font-weight: 200;
  font-size: ${fontSizes.xl};
  margin: ${margins.m} 0px;
  color: ${colors.navy};
  text-align: center;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${margins.xs};
    margin-bottom: 0px;
    font-size: ${fontSizes.l};
  }
`;

export const Title2 = styled.h2`
  font-weight: 200;
  font-size: ${fontSizes.xl};
  margin: ${margins.m} 0px;
  color: ${colors.navy};
  text-align: center;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${margins.xs};
    margin-bottom: 0px;
    font-size: ${fontSizes.l};
  }
`;

export const TitleCategory = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${fontSizes.m};
  padding: 15px;
  margin: 0;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    padding: 3px;
  }
`;

export const Title3 = styled.h3`
  color: ${colors.navy};
  padding: 0 ${margins.m};
  margin: ${margins.xs};
  font-size: ${fontSizes.l};
  font-weight: 300;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    font-size: ${fontSizes.m};
  }
`;

export const Title4 = styled.h4`
  padding: 0 15px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 15px;
    padding: 0px;
  }
`;

export const Title4Top = styled.h4`
  margin: ${margins.m} 0px 0px;
  text-align: center;
  font-size: ${fontSizes.m};
  color: ${colors.navy};
  font-weight: 300;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} ${margins.xs} ${margins.xs};
    padding: ${margins.s} 0 0 0;
  }
`;

export const Question = styled.p`
  padding: 20px 20px 10px 20px;
  color: ${colors.navy};
  text-align: ${props => props.center ? 'center' : 'justify'};
  margin: 10px 0 0 0;
  ${props => props.noTopPadding && 'paddingTop: 0;'}

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    margin: 0px;
    font-size: ${fontSizes.s};
  }
`;


export const Logo = styled.img`
  width: 85px;
  margin-top: -90px;
  border: 1px solid ${colors.linen};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 65px;
    margin-top: -45px;
  }
`;

export const ImgCover = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

export const Block = styled.section`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  width: 80%;
  max-width: 800px;
  margin: 40px 20px 20px;
  background: ${colors.whiteTransparent};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: ${margins.m};
    width: 100%;
    margin: 0px;
  }

  @media (max-width: ${smallMobileThresholdPixels}) {
    padding: 20px 5px 5px;
    width: 100%;
    margin: 0px;
  }
`;

export const Form1 = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.marginTop ? props.marginTop : '30px'};
`;

export const Form2 = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const Input = styled.input`
  margin-bottom: 0px;
  padding: ${margins.m} ${margins.s} ${margins.s};
  background: 0;
  border: 0;
  border-bottom: ${props => !props.error ? '1px dotted #777' : `2px dotted ${colors.error}`};
  font-family: "Roboto", serif;
  color: ${colors.navy};
  font-size: ${fontSizes.m};
  cursor: pointer;
  width: ${props => (props.half || props.halfOnDesktop) ? '48%' : `calc(100% - ${margins.m})`};

  transition: all 0.3s ease;
  opacity: 0.85;

  ${props => !props.center ? '' : `
    &::placeholder{
      text-align: center;
    }
  `}

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      opacity: 1;
    }
  }
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    ${props => props.halfOnDesktop ? `width: calc(100% - ${margins.m})` : ''};
    &::placeholder{
      font-size: ${fontSizes.s};
    }
  }
`;

export const SmallInput = styled.input`
  display: inline-block;
  margin-right: 10px;
  margin-top: -5px;
  width: 15px;
  text-align: center;
  padding: 3px 10px;
  background: 0;
  border: 0;
  color: #000;
  font-family: "Roboto", serif;
  font-size: ${fontSizes.m};
  border: 1px solid #777;
`;

export const Clothes = styled.section`
  margin-top: 20px;
`;

export const ClothesList = styled.section`
  margin: 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1000px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

export const Clothe = styled.article`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #333;
`;

export const Cloth = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 150px;
  margin: 5px 0;
  border-radius: 50%;
  border: 1px solid #333;
  background-color: white;
  transition: all 0.3s ease;
  opacity: 0.85;
  cursor: ${props => props.clickable ? 'pointer' : 'default'};

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      transform: scale(1.1);
      opacity: 1;
    }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    height: 110px;
    width: 110px;
  }
`;

export const ClothText = styled.p`
  margin: 0px 5px;
  color: ${colors.navy};
  font-size: ${fontSizes.s};
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    ${props => props.topMargin && `margin-top: ${margins.s};`}
  }
`;

export const Price = styled.small`
  font-size: 1em;
`;

export const Link = styled.a`
  color: #444;
  cursor: pointer;
`;

export const Link2 = styled.a`
  display: inline-block;
  margin: 20px 0;
  color: #444;
`;

export const Dates = styled.div`
  text-align: center;
`;

function buttonColor(props) {
  if (props.isNotValid) {
    return colors.grey;
  } else if (props.secondary) {
    return colors.white;
  }
  return colors.navy;
}

function buttonPadding(props) {
  if (props.small) {
    return '5px 10px';
  } else if (props.large) {
    return '25px 35px';
  }
  return '20px 25px';
}

export const Button = styled.button`
  min-width: ${props => props.small ? '150px' : '220px'};
  border: ${props => props.secondary ? `1px ${colors.navy} solid` : '0px'};
  border-radius: 0px;
  cursor: pointer;
  text-transform: lowercase;
  font-size: ${props => props.small ? fontSizes.s : fontSizes.m};
  margin: ${props => props.noMargin ? '0px' : '20px auto'};
  padding: ${buttonPadding};
  letter-spacing: 1px;
  background: ${buttonColor};
  color: ${props => props.secondary ? colors.navy : colors.sand};
  transition: all 0.3s ease;
  opacity: 0.9;
  transition:color 500ms ease-out;
  transition:background 500ms ease-out;

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${props => props.small ? fontSizes.s : fontSizes.m};
    margin-top: 15px;
  }
`;

export const Total = styled.span`
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: ${fontSizes.m};
  padding: 20px 0px;
  transition: all .1s cubic-bezier(.7,0,.3,1);
  color: ${props => props.white ? colors.white : colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 10px 0px;
    margin: 2px 0px;
    font-size: ${fontSizes.s};
  }
`;

export const TotalRow = styled.p`
  margin: 10px;
`;

export const Categories = styled.section`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const Category = styled.article`
  border: 1px dotted #333;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.3s ease;
  margin-bottom: 5px;
  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      opacity: 1;
    }
  }
`;

export const Choice = styled.div`
  cursor: pointer;
  padding: 20px;
  color: ${colors.navy};
  border-bottom: 1px dotted #333;
  background: ${props => props.isSelected ? colors.sand : 'initial'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  opacity: 0.9;
  transition: all 0.3s ease;

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      opacity: 1;
      padding-left: 25px;
    }
  }

  @media (max-width: ${smallMobileThresholdPixels}) {
    font-size: 0.8em;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 10px;
    opacity: 1;
  }
`;

export const Slots = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
`;

export const Slot = styled.div`
  box-sizing: border-box;
  flex-basis: auto;
  padding: 20px 0px;
  width: calc(49% - 20px);
  margin: 5px 10px;
  border: 1px solid ${props => props.isSelected ? colors.sand : colors.navy};
  color: ${colors.navy};
  ${props => props.isSelected ? `background: ${colors.sand}` : ''};
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.9;

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    padding: 10px 0px;
  }
`;

export const Days = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    justify-content: space-around;
  }
`;

export const Day = styled.div`
  padding: 20px 4px;
  width: 50px;
  margin: 5px;
  border: 1px solid ${colors.navy};
  border-radius: 60px;
  color: ${colors.navy};
  ${props => props.isSelected ? `background: ${colors.sand}` : ''};
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.9;

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      opacity: 1;
      transform: scale(1.2);
    }
  }
`;

export const SmallText = styled.p`
  margin-top: 10px;
  font-size: ${fontSizes.s};
  text-align: center;
`;

export const Icon = styled.span`
  font-size: 58px;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 38px;
    ${props => props.hideOnMobile && 'display: none;'}
  }
  @media (min-width: ${mobileThresholdPixels}) {
    margin-top: -${margins.s};
  }
`;

export const Icon2 = styled.img`
  width: 50px;
  height: auto;
  margin-right: 15px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 40px;
  }
`;

export const AddRow = styled.div`
  margin-top: -8px;
`;

export const Add = styled.span`
  color: ${colors.navy};
  text-align: right;
  font-size: 48px;
  font-weight: bold;
  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 30px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px dotted #AAA;
  margin: 10px 0px;
`;

export const Note = styled.p`
  margin: 0px ${margins.s};
  text-transform: none;
  font-weight: normal;
  font-size: ${fontSizes.s};
  text-align: justify;
`;

export const ErrorText = styled.p`
  color: ${colors.error};
  text-align: center;
`;

export const SuccessText = styled.p`
  color: ${colors.navy};
  text-align: center;
`;

export const Delete = styled.div`
  background-color: ${colors.navy};
  width: 30px;
  height: 30px;
  border-radius: 15px;
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: ${fontSizes.l};
  cursor: pointer;
  opacity: 0.8;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    top: 3px;
    right: 3px;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }
`;

const quantityAnimation = keyframes`
  0% { transform: scale(0.5);  }
  50%   { transform: scale(1.4); }
  100% { transform: scale(1);  }
`;

export const Quantity = styled.div`
  color: ${colors.navy};
  background-color: ${colors.sand};
  width: 36px;
  height: 36px;
  border-radius: 18px;
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fontSizes.m};

  animation: ${quantityAnimation} ease 0.3s;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    top: 3px;
    left: 3px;
  }

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      opacity: 1;
      transform: scale(1.2);
    }
  }
`;

export const TextLink = styled.a`
  color: ${colors.navy};
  text-decoration: underline;
  cursor: pointer;
`;

export const TextLinkSeparator = styled.span`
  color: ${colors.navy};
  margin-right: 5px;
  margin-left: 5px;
`;
