import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';

import isMobile from '../services/isMobile';
import { configureStore } from '../store';
import seoConfig from '../seoConfig';
import routes from '../Routes';
import { facebookPixelScript, facebookPixelNoScript, intercom, fullstory } from '../vendors';

import '../polyfill/trimStart';
import '../assets/flaticon/flaticon.css';

// Initialize store
// eslint-disable-next-line no-underscore-dangle
const store = configureStore(typeof window !== 'undefined' && window.__INITIAL_STATE__);

const disableScripts = () => typeof window !== 'undefined' && window.location.pathname === '/styleguide';

const disableScriptsExceptOnProd = () => !(process.env.GATSBY_ENV === 'PROD');

const getRoute = routeSlug => routes[routeSlug];

const getTitle = (routeSlug) => {
  if (!routeSlug) return seoConfig.title;
  const { seo = {} } = getRoute(routeSlug);
  return seo.title || seoConfig.title;
};

const getDescription = (routeSlug) => {
  if (!routeSlug) return seoConfig.description;
  const { seo = {} } = getRoute(routeSlug);
  return seo.description || seoConfig.description;
};

const getUrl = (routeSlug) => {
  if (!routeSlug) return seoConfig.baseUrl;
  const { url } = getRoute(routeSlug);
  return `${seoConfig.baseUrl}${url}`;
};

// TODO remove this temporary code to force to unregister SW
// SW was installed on users devices after a failed attempt to setup gatsby-offline-plugin
if (typeof navigator !== 'undefined' && navigator.serviceWorker && navigator.serviceWorker.getRegistrations) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.map(registration => registration.unregister());
  });
}

export const AppContext = createContext({
  isMobile: true,
});

class Layout extends React.Component {
  constructor() {
    super();
    this.state = { isMobile: true };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ isMobile: isMobile() });
  }

  render() {
    const { children, routeSlug } = this.props;
    return (
      <div>
        <Helmet
          title={getTitle(routeSlug)}
          meta={[{ name: 'description', content: getDescription(routeSlug) }]}
          link={[{ rel: 'canonical', href: getUrl(routeSlug) }]}
        />

        {/* disable scripts for styleguide */}
        {disableScripts() ?
          null :
          <Helmet>
            <html lang="fr" />
            <script>{facebookPixelScript}</script>
            <noscript>{facebookPixelNoScript}</noscript>
          </Helmet>}

        {/* enable fullstory and intercom only on prod */}
        {disableScriptsExceptOnProd() ?
          null :
          <Helmet>
            <script
              src="https://cdn.ravenjs.com/3.25.2/raven.min.js" async crossOrigin="anonymous"
              onLoad="Raven.config('https://04d154c0fb284855a70b80991c14f2b5@sentry.io/158955').install()"
            />
            <script>{intercom}</script>
            <script>{fullstory}</script>
          </Helmet>
        }

        <Provider store={store}>
          <AppContext.Provider value={this.state}>
            {children}
          </AppContext.Provider>
        </Provider>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  routeSlug: PropTypes.string,
};

Layout.defaultProps = {
  children: null,
  routeSlug: null,
};

export default Layout;
